import * as React from 'react';

import { FullPageSpinner } from '@futurefit-ai/ffui';

import { useAuth } from '../context/AuthContext';

const AuthenticatedApp = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../components/AuthenticatedApp.component'
    )
);
const UnauthenticatedApp = React.lazy(
  () => import('../components/UnauthenticatedApp.component')
);

function AppRoutes() {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {user.token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
}

export { AppRoutes };
