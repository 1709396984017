export type FormatToPartResult = {
  type: string;
  value: string;
  unit?: string;
};

export enum DurationType {
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

/**
 * Returns the duration type of relative time needed for DURATION_FILTER formatting
 */
export const getDurationUnit = (duration: DurationType) => {
  if (duration.includes(DurationType.YEAR)) {
    return DurationType.YEAR;
  } else if (duration.includes(DurationType.MONTH)) {
    return DurationType.MONTH;
  } else if (duration.includes(DurationType.DAY)) {
    return DurationType.DAY;
  } else if (duration.includes(DurationType.HOUR)) {
    return DurationType.HOUR;
  } else if (duration.includes(DurationType.MINUTE)) {
    return DurationType.MINUTE;
  } else if (duration.includes(DurationType.SECOND)) {
    return DurationType.SECOND;
  } else {
    return DurationType.HOUR;
  }
};

/**
 * Handles the formatting of the formatted relative time in parts
 * @param formattedResult An Array of objects containing the formatted relative time in parts
 * @param originalString The initial value of the string before formatting
 * @returns string of final formatted translation based on language.
 */
export const formatTranslatedDuration = (
  formattedResult: FormatToPartResult[],
  originalString: string
) => {
  const finalTranslation = formattedResult.reduce(
    (total, part: FormatToPartResult) => {
      if (part.type === 'integer' && originalString.includes('30+')) {
        total += '30+';
      } else {
        total += part.value;
      }
      return total;
    },
    ''
  );

  return finalTranslation;
};
