import * as Sentry from '@sentry/react';
import { devtoolsExchange } from '@urql/devtools';
import { useTranslation } from 'react-i18next';
import { authExchange } from '@urql/exchange-auth';
import {
  Cache,
  cacheExchange,
  Data,
  FieldInfo,
  QueryInput,
  ResolveInfo,
  Resolver,
  ScalarObject,
  Variables,
} from '@urql/exchange-graphcache';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { retryExchange } from '@urql/exchange-retry';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { DocumentNode } from 'graphql';
// eslint-disable-next-line import/named
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { FC, useMemo } from 'react';
import {
  CombinedError,
  createClient,
  dedupExchange,
  errorExchange,
  Exchange,
  fetchExchange,
  gql,
  makeOperation,
  Provider,
  stringifyVariables,
} from 'urql';
import { pipe, share, subscribe } from 'wonka';
import { orderBy } from 'lodash';

import { ViewableStages } from '../templates/work/JobTrackerTemplate/JobTrackerTemplate';
import { useAuth } from './AuthContext';
import { DEFAULT_JOBS_LIMIT } from '../templates/recruiter/lib';
import { updateSearchJobsCache } from './utils';
import {
  CandidateRecommender_CandidateRecommenderOutput,
  CandidateRecommender_EmbeddedCandidates,
  CourseRecommender_CourseRecommenderOutput,
  Error_Code,
  GetAllAssessmentsDocument,
  GetRecruiterSavedTalentsDocument,
  GetSavedJobsByUserUuidDocument,
  GetUserProfileQueryVariables,
  JobRecommender_JobRecommenderOutput,
  JobService_JobDto,
  JobService_JobSearchV2ResponseWrapper,
  JobSource,
  QueryGetCandidateRecommendationsArgs,
  QueryGetCourseRecommendationsArgs,
  QueryGetJobRecommendationsArgs,
  QueryGetResourceRecommendationsArgs,
  QuerySearchJobsV2Args,
  ResourceRecommender_ResourceRecommenderOutput,
  SearchJobsV2Query,
  SearchJobsV2QueryVariables,
  WithTypename,
} from '../generated-mesh/graphql';

type GetTagsResponse =
  import('../graphql-mesh/migration-union-types').GetTagsResponse;

type CourseRecommenderCourse =
  import('../graphql-mesh/migration-union-types').CourseRecommenderCourse;
type DeleteSavedJobMutation =
  import('../graphql-mesh/migration-union-types').DeleteSavedJobMutation;
type DeleteSavedJobMutationVariables =
  import('../graphql-mesh/migration-union-types').DeleteSavedJobMutationVariables;
type GetAllAssessmentsQuery =
  import('../graphql-mesh/migration-union-types').GetAllAssessmentsQuery;
type GetRecruiterSavedTalentsQuery =
  import('../graphql-mesh/migration-union-types').GetRecruiterSavedTalentsQuery;
type GetSavedJobsByUserUuidQuery =
  import('../graphql-mesh/migration-union-types').GetSavedJobsByUserUuidQuery;
type GraphCacheConfig =
  import('../graphql-mesh/migration-union-types').GraphCacheConfig;
type JobRecommenderJob =
  import('../graphql-mesh/migration-union-types').JobRecommenderJob;
type Query = import('../graphql-mesh/migration-union-types').Query;
type ResourceRecommenderResource =
  import('../graphql-mesh/migration-union-types').ResourceRecommenderResource;
type SavedTalent = import('../graphql-mesh/migration-union-types').SavedTalent;
type SaveJobMutation =
  import('../graphql-mesh/migration-union-types').SaveJobMutation;
type SaveJobMutationVariables =
  import('../graphql-mesh/migration-union-types').SaveJobMutationVariables;
type SaveTalentsMutation =
  import('../graphql-mesh/migration-union-types').SaveTalentsMutation;
type UnsaveTalentsMutation =
  import('../graphql-mesh/migration-union-types').UnsaveTalentsMutation;
type UpsertAssessmentMutation =
  import('../graphql-mesh/migration-union-types').UpsertAssessmentMutation;
type UpsertAssessmentMutationArgs =
  import('../graphql-mesh/migration-union-types').UpsertAssessmentMutationArgs;

const GRAPHQL_MESH_ENABLED =
  import.meta.env.VITE_GRAPHQL_MESH_ENABLED === 'true';

type LinkUpPaginationResolver = Resolver<
  WithTypename<SearchJobsV2Query>,
  QuerySearchJobsV2Args,
  string | WithTypename<JobService_JobSearchV2ResponseWrapper>
>;

const linkUpJobsPagination = (): LinkUpPaginationResolver => {
  return (parent, fieldArgs, cache, info) => {
    const { parentKey: entityKey, fieldName: currentFieldName } = info;

    const allFields = cache.inspectFields(entityKey);
    const fieldInfos = allFields.filter((fieldInfo) => {
      const fieldInfoArgs = fieldInfo.arguments as QuerySearchJobsV2Args;

      return (
        fieldInfo.fieldName === currentFieldName &&
        fieldInfoArgs?.searchInput?.filters_array?.some((filter) =>
          filter?.providers?.includes(JobSource.LINKUP)
        )
      );
    });

    const currentFieldKey = `${currentFieldName}(${stringifyVariables(
      fieldArgs
    )})`;

    const providers =
      fieldArgs?.searchInput?.filters_array
        ?.map((filter) => filter.providers)
        .flat() ?? [];

    if (!providers.includes(JobSource.LINKUP)) {
      return {
        __typename: 'JobService_JobSearchV2ResponseWrapper',
        jobs: parent.searchJobsV2?.jobs,
        total_pages: parent.searchJobsV2?.total_pages,
        total_results: parent.searchJobsV2?.total_results,
      };
    }

    const isInCache = cache.resolve(
      cache.resolve(entityKey, currentFieldKey) as string,
      'jobs'
    );

    info.partial = !isInCache;

    let results: string[] = [];

    const currentFieldOffset = fieldArgs.searchInput.page as number;

    if (currentFieldOffset === 1) {
      const fieldInfoKey = cache.resolve(entityKey, currentFieldKey) as string;
      results = cache.resolve(fieldInfoKey, 'jobs') as string[];

      cache
        .inspectFields('Query')
        .filter((field) => field.fieldName === currentFieldName)
        .forEach((field) => {
          const hasLinkupProvider = (
            field.arguments as SearchJobsV2QueryVariables
          ).searchInput?.filters_array?.some((filter) =>
            filter.providers?.includes(JobSource.LINKUP)
          );

          if (field.fieldKey !== currentFieldKey && hasLinkupProvider) {
            cache.invalidate('Query', field.fieldKey);
          }
        });
    } else {
      fieldInfos.forEach((fieldInfo) => {
        const key = cache.resolve(entityKey, fieldInfo.fieldKey) as string;
        const fieldResults = cache.resolve(key, 'jobs') as string[];

        results.push(...fieldResults);
      });
    }

    return {
      __typename: 'JobService_JobSearchV2ResponseWrapper',
      jobs: results,
    } as any;
  };
};

type AwarePaginationResolver = <
  Args =
    | QueryGetCourseRecommendationsArgs
    | QueryGetJobRecommendationsArgs
    | QueryGetResourceRecommendationsArgs
    | QueryGetCandidateRecommendationsArgs,
  Output =
    | JobRecommender_JobRecommenderOutput
    | CourseRecommender_CourseRecommenderOutput
    | ResourceRecommender_ResourceRecommenderOutput
    | CandidateRecommender_CandidateRecommenderOutput
>(
  parent: WithTypename<Query>,
  args: Args,
  cache: Cache,
  info: ResolveInfo
) => string | (Output & { __typename?: string });

const recommenderPagination = ({
  offsetArgument = 'page',
  recommenderType = 'jobs',
}: {
  offsetArgument?: string;
  recommenderType?: 'jobs' | 'courses' | 'resources' | 'candidates';
}): AwarePaginationResolver => {
  return (_parent, fieldArgs, cache, info) => {
    const { parentKey: entityKey, fieldName: currentFieldName } = info;

    const allFields = cache.inspectFields(entityKey);
    const fieldInfos = allFields.filter(
      (fieldInfo) => fieldInfo.fieldName === currentFieldName
    );
    const size = fieldInfos.length;

    if (size === 0) {
      // if this is the first time querying (hasn't change pagination yet)
      return undefined as any;
    }

    const currentFieldKey = `${currentFieldName}(${stringifyVariables(
      fieldArgs
    )})`;

    const key = cache.resolve(entityKey, currentFieldKey) as string;
    const embeddedResult = cache.resolve(key, '_embedded') as any;

    const isInCache = cache.resolve(
      embeddedResult,
      recommenderType
    ) as string[];

    // when the query is being triggered, isInCache will be null until results came back
    info.partial = !isInCache;

    let results: string[] = [];

    const fieldQueryArgs = (fieldArgs as any).query
      ? (fieldArgs as any).query
      : (fieldArgs as {
          [key: string]: string | number;
        });
    const currentFieldOffset = fieldQueryArgs[offsetArgument] as number;

    if (currentFieldOffset === 1) {
      // invalidate cache if page = 1 (i.e. startover the accumulation of results, aka restart infinite pagination)
      const fieldInfoKey = cache.resolve(entityKey, currentFieldKey) as string;
      const embeddedResult = cache.resolve(fieldInfoKey, '_embedded') as any;
      results = cache.resolve(embeddedResult, recommenderType) as string[];

      cache
        .inspectFields('Query')
        .filter((field) => field.fieldName === currentFieldName)
        .forEach((field) => {
          // value of the limit variable of entry in the cache
          const fieldLimit = (field.arguments?.query as Variables)?.limit || 0;

          // value of the limit variable of the current query being executed
          const currentFieldLimit = fieldQueryArgs.limit as number;

          // Paginated queries have a limit of 20 while the one on home page has limit of 4
          //  we don't want to empty cached results of the latter when on find jobs.
          //  We also don't want the home page query to empty cached results of find jobs query.
          if (
            field.fieldKey !== currentFieldKey &&
            fieldLimit &&
            (fieldLimit as number) > 6 &&
            currentFieldLimit > 6
          ) {
            cache.invalidate('Query', field.fieldKey);
          }
        });
    } else {
      fieldInfos.forEach((fieldInfo) => {
        const key = cache.resolve(entityKey, fieldInfo.fieldKey) as string;
        const embeddedResult = cache.resolve(key, '_embedded') as any;
        const fieldResults = cache.resolve(
          embeddedResult,
          recommenderType
        ) as string[];
        results.push(...fieldResults);
      });
    }

    return (() => {
      switch (recommenderType) {
        case 'jobs':
          return {
            __typename: GRAPHQL_MESH_ENABLED
              ? 'JobRecommender_JobRecommenderOutput'
              : 'JobRecommenderOutput',
            _embedded: {
              __typename: GRAPHQL_MESH_ENABLED
                ? 'JobRecommender_EmbeddedJobs'
                : 'JobRecommenderEmbedded',
              jobs: results as unknown as JobRecommenderJob[],
            },
          };

        case 'courses':
          return {
            __typename: GRAPHQL_MESH_ENABLED
              ? 'CourseRecommender_CourseRecommenderOutput'
              : 'CourseRecommenderOutput',
            _embedded: {
              __typename: GRAPHQL_MESH_ENABLED
                ? 'CourseRecommender_EmbeddedCourses'
                : 'CourseRecommenderEmbedded',
              courses: results as unknown as CourseRecommenderCourse[],
            },
          };

        case 'resources':
          return {
            __typename: GRAPHQL_MESH_ENABLED
              ? 'ResourceRecommender_ResourceRecommenderOutput'
              : 'ResourceRecommenderOutput',
            _embedded: {
              __typename: GRAPHQL_MESH_ENABLED
                ? 'ResourceRecommender_EmbeddedResources'
                : 'ResourceRecommenderEmbedded',
              resources: results as unknown as ResourceRecommenderResource[],
            },
          };

        case 'candidates':
          return {
            __typename: 'CandidateRecommender_CandidateRecommenderOutput',
            _embedded: {
              __typename: 'CandidateRecommender_EmbeddedCandidates',
              candidates:
                results as unknown as CandidateRecommender_EmbeddedCandidates[],
            },
          };
      }
    })();
  };
};

const betterUpdateQuery = <Result, Query>(
  cache: Cache,
  queryInput: QueryInput,
  result: any,
  fn: (res: Result, query: Query) => Query
) => {
  return cache.updateQuery(
    queryInput,
    (data) => fn(result, data as any) as any
  );
};

const expirationThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds

const didRequestErrorOnAuth = (error: CombinedError) =>
  error.graphQLErrors.some(
    // this is the code that is returned by the gateway when a token is invalid
    (e) => e.extensions?.code === Error_Code.UNAUTHORIZED
  );

export const getGqlString = (doc: DocumentNode): string | undefined =>
  doc.loc && doc.loc.source.body;

// sentryExchange shares the graphql query and payload with Sentry for
// debugging and error handling purposes.
const sentryExchange: Exchange = ({ forward }) => {
  return (ops$) => {
    const shared = pipe(ops$, share);
    pipe(
      shared,
      subscribe((query) => {
        Sentry.addBreadcrumb({
          category: 'graphql',
          message: `POST ${query.context.url}`,
          data: {
            graphql: getGqlString(query.query),
            variables: query.variables,
          },
          level: 'info',
        });
      })
    );
    return forward(shared);
  };
};

const createUrqlClient = ({
  getSession,
}: {
  getSession: () => Promise<CognitoUserSession | null>;
}) => {
  return createClient({
    url: import.meta.env.VITE_GRAPHQL_HOST || 'localhost',
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      cacheExchange<GraphCacheConfig>({
        optimistic: {
          saveJob: (variables: any, cache: any, _info: any) => {
            const queryArgs = GRAPHQL_MESH_ENABLED
              ? variables.save
              : variables.query;
            const stage = queryArgs?.stage as ViewableStages;
            const jobUuid = queryArgs?.uuid;
            if (!jobUuid) {
              // when saving job for the first time
              return undefined;
            }

            const stageTypename = GRAPHQL_MESH_ENABLED
              ? 'JobService_Stage'
              : 'Stage';

            const job = cache.readFragment(
              gql`
                fragment _ on JobService_SavedJob {
                  __typename
                  company
                  created_at
                  description
                  job {
                    __typename
                    ingested_at
                    source
                    source_type
                    uuid
                  }
                  location
                  notes
                  stage_history {
                    __typename
                    created_at
                    stage
                  }
                  title
                  uuid
                }
              `,
              { uuid: jobUuid }
            );

            return {
              ...job,
              stage_history: [
                {
                  created_at: new Date().toISOString(),
                  stage,
                  __typename: stageTypename,
                },
                ...job.stage_history,
              ],
            };
          },
          deleteSavedJob: (variables: any, cache: any, _info: any) => {
            const queryArgs = variables.body
              ? (variables.body as DeleteSavedJobMutationVariables)
              : variables;
            const jobUuid = queryArgs.uuid;

            const job = cache.readFragment(
              gql`
                fragment _ on JobService_SavedJob {
                  __typename
                  company
                  created_at
                  description
                  job {
                    __typename
                    ingested_at
                    source
                    source_type
                    uuid
                  }
                  location
                  notes
                  stage_history {
                    __typename
                    created_at
                    stage
                  }
                  title
                  uuid
                }
              `,
              { uuid: jobUuid }
            );

            return job;
          },
          saveTalents: (variables: any, cache: any, _info: any) => {
            const queryArgs = variables.body ? variables.body : variables;

            const talent_user_uuids = queryArgs?.input.talent_user_uuids;
            const user_uuid = queryArgs?.user_uuid;

            if (!user_uuid || !talent_user_uuids) {
              return;
            }

            return cache.readFragment(
              gql`
                fragment _ on SavedTalent {
                  __typename
                  talent_uuid
                  user_uuid
                }
              `,
              { talent_uuid: talent_user_uuids[0] }
            );
          },
          unsaveTalents: (variables: any, cache: any, _info: any) => {
            const queryArgs = variables.body ? variables.body : variables;
            const talent_user_uuids = queryArgs?.input.talent_user_uuids;
            const user_uuid = queryArgs?.user_uuid;

            if (!user_uuid || !talent_user_uuids) {
              return;
            }

            return cache.readFragment(
              gql`
                fragment _ on SavedTalent {
                  __typename
                  talent_uuid
                  user_uuid
                }
              `,
              { user_uuid, talent_user_uuids }
            );
          },
        },
        updates: {
          Mutation: {
            addContact(_result, args, cache, _info) {
              cache
                .inspectFields({ __typename: 'Query' })
                // Only invalidate the cache for the user to whose profile the contact was added
                .filter(
                  (field) =>
                    field.fieldName === 'getUserProfile' &&
                    (
                      field.arguments
                        ?.getProfileInput as GetUserProfileQueryVariables
                    ).user_uuid === args.addContactInput.user_uuid
                )
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });

              // Parse the url to check if user is on the
              // /jobs/:jobId route
              const url = new URL(window.location.href);
              const pathSegments = url.pathname.split('/');
              const jobsIndex = pathSegments.indexOf('jobs');
              const jobIdIndex =
                jobsIndex + 1 < pathSegments.length ? jobsIndex + 1 : -1;

              // If the user is on the /jobs/:jobId route, then
              // that means they are filtering talent by a specific job.
              // Only in this case will the previously_contacted field
              // be visible, thus we update the cache.
              if (jobsIndex > -1 && jobIdIndex > -1) {
                // Update the previously_contacted field on the candidate
                // to whom we added a contact to true. This allows us to
                // save a network request to getCandidateRecommendations (again)
                cache.writeFragment(
                  gql`
                    fragment _ on CandidateRecommender_Candidate {
                      previously_contacted
                    }
                  `,
                  {
                    id: args.addContactInput.user_uuid,
                    previously_contacted: true,
                  }
                );
              }

              // If user is not on that route, then previously_contacted
              // should always be false. Thus, we don't need to update the cache.
            },
            deleteResume(_result, _args, cache, _info) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((field) => field.fieldName === 'getPostObjectURL')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateTenantWorkConfiguration(_result, _args, cache, _info) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((field) => field.fieldName === 'getTenantConfiguration')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            upsertUserNextStep(
              _result: any,
              args: any,
              cache: any,
              _info: any
            ) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getUserNextSteps' ||
                    // Next steps can also mutate assessments when completed
                    field.fieldName === 'getAllAssessments'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            deleteUserNextStep(
              _result: any,
              _args: any,
              cache: any,
              _info: any
            ) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getUserNextSteps' ||
                    field.fieldName === 'getAllAssessments'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            createInviteLink(_result: any, args: any, cache: any, _info: any) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((field: any) => field.fieldName === 'getInviteLinks')
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateInviteLink(_result: any, args: any, cache: any, _info: any) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((field: any) => field.fieldName === 'getInviteLinks')
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            createReportExport(
              _result: any,
              args: any,
              cache: any,
              _info: any
            ) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getCombinedExportReports' ||
                    field.fieldName === 'getExportReports' ||
                    field.fieldName === 'getWeeklyUserProfilesReports'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            createUserProfilesReport(
              _result: any,
              args: any,
              cache: any,
              _info: any
            ) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getCombinedExportReports' ||
                    field.fieldName === 'getExportReports' ||
                    field.fieldName === 'getWeeklyUserProfilesReports'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            requestSubmissionsExportation(
              _result: any,
              args: any,
              cache: any,
              _info: any
            ) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((field: any) => field.fieldName === 'getExportReports')
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            delNextStepDoc(_result: any, args: any, cache: any, _info: any) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) => field.fieldName === 'downloadNextStepDoc'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            confirmNextStepDoc(
              _result: any,
              _args: any,
              cache: any,
              _info: any
            ) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) => field.fieldName === 'downloadNextStepDoc'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            createUserTags(_result: any, _args: any, cache: any, _info: any) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getUserProfile' ||
                    field.fieldName === 'getUserProfiles' ||
                    field.fieldName === 'getAllUserUuids' ||
                    field.fieldName === 'getAllTags' ||
                    field.fieldName === 'getProfileFilters' ||
                    field.fieldName === 'getTags'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            upsertTag(result: any, args: any, cache: Cache, _info: any) {
              const tagId = GRAPHQL_MESH_ENABLED
                ? args.upsertTagInput.tag_uuid
                : args.query.tag_uuid;
              const isEditing = !!tagId;

              const rootQuery = {
                query: GRAPHQL_MESH_ENABLED
                  ? `
                  query GetTags($getTagsInput: GetTagsInput!) {
                    getTags(getTagsInput: $getTagsInput) {
                      items {
                        tag_uuid
                        label
                        status
                        category
                        tenant_uuid
                        updated_at
                        updated_by
                        created_at
                        created_by
                      }
                      count
                      next
                    }
                  }
                `
                  : `
                  query GetTags($getTagsInput: GetTagsInput!) {
                    getTags(query: {getTagsInput: $getTagsInput}) {
                      items {
                        tag_uuid
                        label
                        status
                        category
                        tenant_uuid
                        updated_at
                        updated_by
                        created_at
                        created_by
                      }
                      count
                      next
                    }
                  }
                `,
              };

              const allCachedTagsQueries = cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter((field: FieldInfo) => field.fieldName === 'getTags');

              const allCachedTagsPages = allCachedTagsQueries.map(
                (field: FieldInfo) => ({
                  ...rootQuery,
                  variables: field.arguments?.query
                    ? field.arguments?.query
                    : field.arguments,
                })
              );

              if (!isEditing) {
                const unpagedField = allCachedTagsQueries.find(
                  (field: FieldInfo) => {
                    const { getTagsInput } = (field.arguments?.query ??
                      field.arguments) as ScalarObject;
                    return (
                      !Object.prototype.hasOwnProperty.call(
                        getTagsInput,
                        'skip'
                      ) &&
                      !Object.prototype.hasOwnProperty.call(
                        getTagsInput,
                        'take'
                      )
                    );
                  }
                );

                /**
                 * If we're adding a new tag and
                 * we have a getTags with no paging cached result
                 * then add it to the top of the list
                 *  */
                const unpagedQuery = {
                  ...rootQuery,
                  variables:
                    unpagedField?.arguments?.query ?? unpagedField?.arguments,
                };

                cache.updateQuery(unpagedQuery, (data: any) => {
                  if (!data || !unpagedQuery.variables) return data;

                  // Just add this new tag to the top of the page1
                  const newTagsPageData = data.getTags as GetTagsResponse;
                  newTagsPageData.items = [
                    result.upsertTag,
                    ...newTagsPageData.items,
                  ];

                  return {
                    getTags: newTagsPageData,
                    __typename: 'getTagsResponse',
                  } as Data;
                });
              } else {
                // Do an in-place replacement for that tag
                for (const queryWithVariablesArgs of allCachedTagsPages) {
                  cache.updateQuery(queryWithVariablesArgs, (data: any) => {
                    if (!data) return data;

                    const newTagsPageData: GetTagsResponse = data.getTags;
                    const tagIndex = newTagsPageData.items.findIndex(
                      ({ tag_uuid }) => tag_uuid === tagId
                    );

                    if (tagIndex === -1) {
                      return {
                        getTags: newTagsPageData,
                        __typename: 'getTagsResponse',
                      } as Data;
                    }

                    const argsInput = args.query
                      ? args.query
                      : args.upsertTagInput;

                    const mutatedTag = {
                      ...newTagsPageData.items[tagIndex],
                      ...argsInput,
                    };
                    // Just do an in-place update
                    newTagsPageData.items[tagIndex] = mutatedTag;
                    return {
                      getTags: newTagsPageData,
                      __typename: 'getTagsResponse',
                    } as Data;
                  });
                }
              }

              // Invalidate other results affected by this mutation
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field) =>
                    field.fieldName === 'getUserProfile' ||
                    field.fieldName === 'getUserProfiles' ||
                    field.fieldName === 'getAllUserUuids' ||
                    field.fieldName === 'getAllTags' ||
                    field.fieldName === 'getProfileFilters'
                )
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateUserProfile(_result: any, args: any, cache: any, _info: any) {
              const input = args.query ? args.query : args;

              if (input?.tags) {
                cache
                  .inspectFields({ __typename: 'Query' })
                  .filter(
                    (field: any) =>
                      field.fieldName === 'getUserProfile' ||
                      field.fieldName === 'getUserProfiles' ||
                      field.fieldName === 'getAllUserUuids' ||
                      field.fieldName === 'getAllTags' ||
                      field.fieldName === 'getProfileFilters'
                  )
                  .forEach((field: any) => {
                    cache.invalidate('Query', field.fieldKey);
                  });
              }

              if (input?.target_career) {
                cache
                  .inspectFields({
                    __typename: 'Query',
                  })
                  .filter((field: any) => field.fieldName === 'getUserProfile')
                  .forEach((field: any) => {
                    cache.invalidate('Query', field.fieldKey);
                  });
              }

              if (input?.pref_lang || input?.is_onboarded) {
                // If the user changes their pref_lang or finishes onboarding, we invalidate the entire cache
                cache
                  .inspectFields({
                    __typename: 'Query',
                  })
                  .forEach((field: any) => {
                    cache.invalidate('Query', field.fieldKey);
                  });
              }

              if (
                input?.updateProfileInput.ethnicities ||
                input?.updateProfileInput.communitiesObject ||
                input?.updateProfileInput.employment_status
              ) {
                cache
                  .inspectFields({
                    __typename: 'Query',
                  })
                  .filter(
                    (field: any) =>
                      field.fieldName === 'getUserProfile' ||
                      field.fieldName === 'getUserProfiles' ||
                      field.fieldName === 'getAllUserUuids'
                  )
                  .forEach((field: any) => {
                    cache.invalidate('Query', field.fieldKey);
                  });
              }
            },
            updateAccountPreferences(
              _result: any,
              _args: any,
              cache: any,
              _info: any
            ) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter((field: any) => field.fieldName === 'getUserProfile')
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            inviteToVervoeAssessment(
              _result: any,
              _args: any,
              cache: Cache,
              _info: any
            ) {
              // Invalidate assessments and next steps results.
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getUserNextSteps' ||
                    field.fieldName === 'getAllAssessments'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            upsertAssessment(
              _result: any,
              args: UpsertAssessmentMutationArgs,
              cache: any,
              _info: any
            ) {
              const user_uuid = args?.upsertAssessmentInput?.user_uuid ?? '';

              betterUpdateQuery<
                UpsertAssessmentMutation,
                GetAllAssessmentsQuery
              >(
                cache,
                {
                  query: GetAllAssessmentsDocument,
                  variables: { user_uuid },
                },
                _result,
                (result, query) => {
                  // If we update an existing entity, do nothing -> let urql handle the rest
                  if (
                    query.getAllAssessments.some(
                      (assessment) =>
                        assessment.assessment_uuid ===
                        result.upsertAssessment.assessment_uuid
                    )
                  ) {
                    return query;
                  }

                  // If we add a new entity, then update the list for getAllAssessments query
                  return {
                    getAllAssessments: [
                      result.upsertAssessment,
                      ...query.getAllAssessments,
                    ],
                  };
                }
              );
            },
            saveJob(_result: any, args: any, cache: any, _info: any) {
              const queryArgs = args.query
                ? (args.query as SaveJobMutationVariables['save'])
                : args;
              const stage = GRAPHQL_MESH_ENABLED
                ? queryArgs?.save?.stage
                : (queryArgs?.stage as ViewableStages);

              const objectWithUserUuid = cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) => field.fieldName === 'savedJobsByUserUUID'
                )[0].arguments as any;
              const userUuid = objectWithUserUuid.body
                ? objectWithUserUuid.body.user_uuid
                : objectWithUserUuid.user_uuid;

              const savedJobsTypename = GRAPHQL_MESH_ENABLED
                ? 'JobService_SavedJob'
                : 'SavedJob';
              const stageTypename = GRAPHQL_MESH_ENABLED
                ? 'JobService_Stage'
                : 'Stage';

              betterUpdateQuery<SaveJobMutation, GetSavedJobsByUserUuidQuery>(
                cache,
                {
                  query: GetSavedJobsByUserUuidDocument,
                  variables: {
                    userUuid,
                  },
                },
                _result,
                (result: any, query: any) => {
                  if (!result.saveJob) {
                    return { savedJobsByUserUUID: query.savedJobsByUserUUID };
                  }
                  // saving the first time - no saved uuid
                  if (
                    !query.savedJobsByUserUUID.some(
                      (j: any) => j.job.source === result.saveJob.job.source
                    )
                  ) {
                    return {
                      savedJobsByUserUUID: [
                        ...query.savedJobsByUserUUID,
                        {
                          ...result.saveJob,
                          __typename: savedJobsTypename,
                        },
                      ],
                    };
                  }

                  // updating job stage - has a saved uuid
                  return {
                    savedJobsByUserUUID: query.savedJobsByUserUUID.map(
                      (job: any) => {
                        if (job.job.source === result.saveJob.job.source) {
                          return {
                            ...job,
                            stage_history: [
                              {
                                created_at: new Date().toISOString(),
                                stage,
                                __typename: stageTypename,
                              },
                              ...job.stage_history,
                            ],
                            __typename: savedJobsTypename,
                          };
                        } else return job;
                      }
                    ),
                  };
                }
              );
            },
            deleteSavedJob(_result: any, _args: any, cache: any, _info: any) {
              const objectWithUserUuid = cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) => field.fieldName === 'savedJobsByUserUUID'
                )[0].arguments as any;
              const userUuid = objectWithUserUuid.body
                ? objectWithUserUuid.body.user_uuid
                : objectWithUserUuid.user_uuid;

              betterUpdateQuery<
                DeleteSavedJobMutation,
                GetSavedJobsByUserUuidQuery
              >(
                cache,
                {
                  query: GetSavedJobsByUserUuidDocument,
                  variables: {
                    userUuid,
                  },
                },
                _result,
                (result, query) => {
                  return {
                    savedJobsByUserUUID: (
                      query.savedJobsByUserUUID as any[]
                    ).filter((j) => j.uuid !== result.deleteSavedJob.uuid),
                  };
                }
              );
            },
            updateDraftRoadmap(
              _result: any,
              args: any,
              cache: any,
              _info: any
            ) {
              // If the user saves draft roadmap, we invalidate the roadmap cache
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter((field: any) => field.fieldName === 'getRoadmap')
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            publishRoadmap(_result: any, args: any, cache: any, _info: any) {
              // If the user publishes roadmap, we invalidate the roadmap cache
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter((field: any) => field.fieldName === 'getRoadmap')
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            publishForm(_result: any, _args: any, cache: any, _info: any) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getForms' ||
                    field.fieldName === 'getOnboardingForm' ||
                    field.fieldName === 'getFormDetails' ||
                    field.fieldName === 'getDefaultQuestions'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            addNextStepToLibrary(_result, args, cache, _info) {
              // If the user saves to library roadmap, we invalidate the next steps library cache
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter((field) => field.fieldName === 'getNextStepsLibrary')
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            assignCoach(_result: any, args: any, cache: any) {
              // If the coach assigns users, we invalidate the profiles cache
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getUserProfiles' ||
                    field.fieldName === 'getAllUserUuids'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            createEmployer(_result: any, args: any, cache: any) {
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getEmployers' ||
                    field.fieldName === 'getRecruiters'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateEmployer(_result: any, args: any, cache: any) {
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getEmployers' ||
                    field.fieldName === 'getRecruiters'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            deleteEmployer(_result: any, args: any, cache: any) {
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getEmployers' ||
                    field.fieldName === 'getRecruiters'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            createRecruiter(_result: any, args: any, cache: any) {
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getRecruiters' ||
                    field.fieldName === 'getEmployers'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateRecruiter(_result: any, args: any, cache: any) {
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getRecruiters' ||
                    field.fieldName === 'getEmployers'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            deleteRecruiters(_result: any, args: any, cache: any) {
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getRecruiters' ||
                    field.fieldName === 'getEmployers'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            removeUserSkills(parent: any, args: any, cache: any) {
              cache.invalidate(
                {
                  __typename: 'Profile',
                  user_uuid: args.removeUserSkillsInput.userUUID,
                },
                'skills'
              );
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getJobRecommendationsByIds'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            addUserSkills(parent: any, args: any, cache: any) {
              cache.invalidate(
                {
                  __typename: 'Profile',
                  user_uuid: args.addUserSkillsInput.userUUID,
                },
                'skills'
              );
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getJobRecommendationsByIds'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateUserSkills(parent: any, args: any, cache: any) {
              cache.invalidate(
                {
                  __typename: 'Profile',
                  user_uuid: args.updateUserSkillsInput.userUUID,
                },
                'skills'
              );
            },
            addUserCaseNote(_result: any, args: any, cache: any, _info: any) {
              const userIdFromArgs = args.query
                ? args.query.createUserCaseNoteInput.user_uuid
                : args.createUserCaseNoteInput.user_uuid;

              return cache
                .inspectFields({ __typename: 'Query' })
                .filter(({ arguments: fieldArguments, fieldName }: any) => {
                  const cachedQueryInput = (
                    fieldArguments?.query
                      ? fieldArguments.query
                      : fieldArguments
                  )?.getUserCaseNotesInput;

                  return (
                    fieldName === 'getUserCaseNotes' &&
                    cachedQueryInput?.user_uuid === userIdFromArgs
                  );
                })
                .forEach(({ fieldKey }: any) =>
                  cache.invalidate('Query', fieldKey)
                );
            },
            deleteUserCaseNote(
              _result: any,
              args: any,
              cache: any,
              _info: any
            ) {
              const userIdFromArgs = args.query
                ? args.query.deleteUserCaseNoteInput.user_uuid
                : args.deleteUserCaseNoteInput.user_uuid;

              return cache
                .inspectFields({ __typename: 'Query' })
                .filter(({ arguments: fieldArguments, fieldName }: any) => {
                  const cachedQueryInput = (
                    fieldArguments?.query
                      ? fieldArguments.query
                      : fieldArguments
                  )?.getUserCaseNotesInput;

                  return (
                    fieldName === 'getUserCaseNotes' &&
                    cachedQueryInput?.user_uuid === userIdFromArgs
                  );
                })
                .forEach(({ fieldKey }: any) =>
                  cache.invalidate('Query', fieldKey)
                );
            },
            saveTalents(_result: any, _args: any, cache: any, _info: any) {
              const userUuid = _args.user_uuid;
              const talentUserUuids = _args.input?.talent_user_uuids;
              if (!userUuid) {
                return;
              }

              betterUpdateQuery<
                SaveTalentsMutation,
                GetRecruiterSavedTalentsQuery
              >(
                cache,
                {
                  query: GetRecruiterSavedTalentsDocument,
                  variables: {
                    user_uuid: userUuid,
                  },
                },
                _result,
                (_result, query) => {
                  const newEntries = talentUserUuids?.map(
                    (talent_user_uuid: any) => ({
                      user_uuid: userUuid,
                      talent_uuid: talent_user_uuid,
                      __typename: 'SavedTalent',
                    })
                  );
                  return {
                    getRecruiterSavedTalents:
                      query.getRecruiterSavedTalents?.concat(
                        newEntries as SavedTalent[]
                      ),
                  };
                }
              );
            },
            unsaveTalents(_result: any, _args: any, cache: any, _info: any) {
              const userUuid = _args.user_uuid;
              const talentUserUuids = _args.input?.talent_user_uuids;

              if (!userUuid) {
                return;
              }

              betterUpdateQuery<
                UnsaveTalentsMutation,
                GetRecruiterSavedTalentsQuery
              >(
                cache,
                {
                  query: GetRecruiterSavedTalentsDocument,
                  variables: {
                    user_uuid: userUuid,
                  },
                },
                _result,
                (result, query) => {
                  return {
                    getRecruiterSavedTalents:
                      query.getRecruiterSavedTalents?.filter(
                        (saved_talent) =>
                          !talentUserUuids?.includes(saved_talent.talent_uuid)
                      ),
                  };
                }
              );
            },
            updateTenant(_result: any, args: any, cache: any, _info: any) {
              // If the user updates tenant, we invalidate the tenant cache
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) => field.fieldKey === 'getCurrentUserTenant'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateSubmission(_result: any, args: any, cache: any, _info: any) {
              // If the user updates tenant, we invalidate the tenant cache
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) => field.fieldName === 'getFormAndLastSubmission'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            saveSubmission(_result: any, args: any, cache: any, _info: any) {
              // If the user updates tenant, we invalidate the tenant cache
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) => field.fieldName === 'getFormAndLastSubmission'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            upsertUserRole(_result: any, _args: any, cache: any) {
              // When admin changes users' role (e.g. from Manage users table), we invalidate cache for queries that are related to users' roles. This ensures admins can have an up-to-date list of users with their correct roles, without refreshing the screen.
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getUserProfiles' ||
                    field.fieldName === 'getAllUserUuids' ||
                    field.fieldName === 'getAllCoaches'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            deleteUsers(_result: any, args: any, cache: any, _info: any) {
              // If admins delete users, we invalidate the queries in Manage users table
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getUserProfiles' ||
                    field.fieldName === 'getAllUserUuids' ||
                    field.fieldName === 'getAllCoaches'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateDefaultQuestions(
              _result: any,
              args: any,
              cache: any,
              _info: any
            ) {
              // If the user saves draft roadmap, we invalidate the roadmap cache
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) => field.fieldName === 'getDefaultQuestions'
                )
                .forEach((field: any) =>
                  cache.invalidate('Query', field.fieldKey)
                );
            },
            updateFormVersion(_result, _args, cache) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field) =>
                    field.fieldName === 'getOnboardingForm' ||
                    field.fieldName === 'getDefaultQuestions'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createFormVersion(_result, _args, cache) {
              cache
                .inspectFields({ __typename: 'Query' })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getOnboardingForm' ||
                    field.fieldName === 'getDefaultQuestions'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateOnboardingFormActivationStatus(_result, _args, cache) {
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field) =>
                    field.fieldName === 'getOnboardingForm' ||
                    field.fieldName === 'getFormDetails' ||
                    field.fieldName === 'getCurrentUserTenant'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createDefaultQuestions(
              _result: any,
              args: any,
              cache: any,
              _info: any
            ) {
              // If the user saves draft roadmap, we invalidate the roadmap cache
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) => field.fieldName === 'getDefaultQuestions'
                )
                .forEach((field: any) =>
                  cache.invalidate('Query', field.fieldKey)
                );
            },
            updateConfiguration(
              _result: any,
              args: any,
              cache: any,
              _info: any
            ) {
              // If the user updates configuration, we invalidate the careers, resources and learning providers cache
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getCareers' ||
                    field.fieldName === 'getResources' ||
                    field.fieldName === 'getLearningProviders'
                )
                .forEach((field: any) =>
                  cache.invalidate('Query', field.fieldKey)
                );
            },
            createPostObjectURL(_result: any, args: any, cache: any) {
              cache
                .inspectFields({
                  __typename: 'Query',
                })
                .filter(
                  (field: any) =>
                    field.fieldName === 'getUserProfile' ||
                    field.fieldName === 'getUserProfiles' ||
                    field.fieldName === 'getAllUserUuids' ||
                    field.fieldName === 'getWorkExperience' ||
                    field.fieldName === 'getWorkExperienceAndSkills' ||
                    field.fieldName === 'getPostObjectURL'
                )
                .forEach((field: any) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateManualJob(result: any, args: any, cache: any, _info: any) {
              if (!result?.updateManualJob?.job) return;
              const { active: activeInput } = args?.updateManualJobInput || {};

              // the updateManualJobInput active = true = restore the archived job
              // we add the restore job back to the searchJob active job list and (filter is_active === true)
              // remove the restore active job from the searchjob archived job list (filter is_active === false)
              updateSearchJobsCache(cache, (data, field) => {
                const {
                  limit = DEFAULT_JOBS_LIMIT,
                  filters_array,
                  sort: { sort_by: sortByKey, is_ascending: isAscending },
                } = field?.arguments?.searchInput || {};

                // if not update the job active status = not restore the arrived job
                // we don't need to handle the cache of searchJobs result job list
                if (!activeInput) {
                  return data;
                }

                const isActive = filters_array?.[0]?.is_active; // is_active is same for each filters object in filters array
                const { jobDto: updatedJob } = result?.updateManualJob || {};
                const {
                  jobs: searchJobs,
                  total_results: searchJobsTotalResults,
                } = data?.searchJobsV2 || {};
                let jobs = [...searchJobs];
                let totalResults = searchJobsTotalResults;
                const findUpdatedJob = jobs.find(
                  (job) => job.job_id === updatedJob.job_id
                );

                // add the archived job back to active job list following the sort order
                if (isActive && !findUpdatedJob) {
                  jobs = orderBy(
                    [updatedJob, ...jobs],
                    [sortByKey],
                    [isAscending ? 'asc' : 'desc']
                  );
                  totalResults += 1;
                  if (jobs.length > limit) {
                    jobs.pop();
                  }
                }

                /**
                 * remove the restored job (active now) in the archive list if found
                 * is_active === undefined means no active filter
                 * is_active === false means querying archived jobs
                 */
                if (isActive === false && findUpdatedJob) {
                  jobs = jobs.filter((job) => job.job_id !== updatedJob.job_id);
                  const filteredJobsCount = searchJobs.length - jobs.length;
                  totalResults -= filteredJobsCount;
                }

                return {
                  searchJobsV2: {
                    ...data.searchJobsV2,
                    total_results: totalResults,
                    jobs,
                  },
                };
              });
            },
            createManualPostJob(
              result: any,
              _args: any,
              cache: any,
              _info: any
            ) {
              if (!result?.createManualPostJob?.jobDto) return;
              updateSearchJobsCache(cache, (data, field) => {
                const {
                  limit = DEFAULT_JOBS_LIMIT,
                  page = 1,
                  search,
                } = field.arguments.searchInput || {};
                const { jobDto: createdJob } =
                  result?.createManualPostJob || {};
                // no update the jobs cache when it is existed in the search job results or the query is not on the first page
                if (
                  data.searchJobsV2.jobs.some(
                    (job: JobService_JobDto) => job.job_id === createdJob.job_id
                  ) ||
                  page !== 1 ||
                  search
                ) {
                  return data;
                }

                const jobs = [createdJob, ...data.searchJobsV2.jobs];

                if (jobs.length > limit) {
                  // if the job array exceed the page limit
                  jobs.pop();
                }
                // cache the total result counts
                const total_results = data.searchJobsV2.total_results + 1;
                return {
                  searchJobsV2: {
                    ...data.searchJobsV2,
                    total_pages: Math.ceil(total_results / DEFAULT_JOBS_LIMIT),
                    total_results,
                    jobs,
                  },
                };
              });
            },
            archiveManualJob(result: any, _args: any, cache: any, _info: any) {
              if (!result?.archiveManualJob?.job) return;
              updateSearchJobsCache(cache, (data, field) => {
                const {
                  limit = DEFAULT_JOBS_LIMIT,
                  filters_array,
                  sort: { sort_by: sortByKey, is_ascending: isAscending },
                } = field?.arguments?.searchInput || {};

                const isActive = filters_array?.[0].is_active;
                const { jobDto: archivedJob } = result.archiveManualJob;
                const {
                  jobs: searchJobs,
                  total_results: searchJobsTotalResults,
                } = data?.searchJobsV2 || {};
                let jobs = [...searchJobs];
                let totalResults = searchJobsTotalResults;
                const findArchivedJob = jobs.find(
                  (job) => job.job_id === archivedJob.job_id
                );

                // remove the archived job from the active query result
                if (isActive && findArchivedJob) {
                  jobs = jobs.filter(
                    (job) => job.job_id !== archivedJob.job_id
                  );
                  const filteredJobsCount =
                    data.searchJobsV2.jobs.length - jobs.length; // in case no job has been filtered.
                  totalResults -= filteredJobsCount;
                }

                // add the archived job to the archived query result
                if (isActive === false && !findArchivedJob) {
                  jobs = orderBy(
                    [archivedJob, ...jobs],
                    [sortByKey],
                    [isAscending ? 'asc' : 'desc']
                  );
                  totalResults += 1;
                  if (jobs.length > limit) {
                    jobs.pop();
                  }
                }

                return {
                  searchJobsV2: {
                    ...data.searchJobsV2,
                    total_results: totalResults,
                    jobs,
                  },
                };
              });
            },
          },
        },
        resolvers: {
          Query: {
            searchJobsV2: linkUpJobsPagination(),
            getCourseRecommendations: recommenderPagination({
              recommenderType: 'courses',
            }),
            getResourceRecommendations: recommenderPagination({
              recommenderType: 'resources',
            }),
            getCandidateRecommendations: recommenderPagination({
              recommenderType: 'candidates',
            }),
          },
        },
        keys: {
          Configuration: (data) => data.tenant_id!,
          Contact: (data: any) => data.contact_uuid!,
          Filters: () => null,
          GenereateURLReponse: () => null,
          MonetaryValue: () => null,
          JobTitleClassifierOutput: (data: any) => data.uuid!,
          ProfilesQueryWrapper: () => null,
          SkillClassifierOutput: (data: any) => data.uuid!,
          CareerRecommenderOutput: () => null,
          CareerRecommenderEmbedded: () => null,
          CourseRecommenderEmbedded: () => null,
          CourseRecommenderOutput: () => null,
          CourseRecommenderCost: () => null,
          Job: (data: any) => data.jobkey!,
          JobResult: () => null,
          Link: () => null,
          Profile: (data: any) => data.user_uuid!,
          ResourceRecommenderResourceCost: () => null,
          ResourceRecommenderResourceProvider: (data: any) => data.id!,
          ResourceRecommenderEmbedded: () => null,
          ResourceRecommenderOutput: () => null,
          RecommenderCollectionLinks: () => null,
          RecommenderLink: () => null,
          Skill: (data: any) => data.id! ?? data.skill_uuid!,
          UserNextStepsWrapper: () => null,
          UserNextSteps: (data: any) => data.next_step_uuid!,
          WorkExperience: (data: any) => data.work_exp_uuid!,
          EducationExperience: (data: any) => data.education_exp_uuid!,
          Certification: (data: any) => data.certification_uuid!,
          Role: () => null,
          Label: () => null,
          NextStepsMetadata: () => null,
          Assessment: (data: any) => data.assessment_uuid!,
          Tag: (data: any) => data.tag_uuid!,
          UserTag: (data: any) => data.tag_uuid!,
          GetTagsResponse: () => null,
          TargetCareer: (data: any) => data.career_uuid!,
          InterestProfile: () => null,
          SaveCareerStatusOutput: (data: any) => data.careerUuid!,
          SaveLearningStatusOutput: (data: any) => data.learningUuid!,
          SaveResourceStatusOutput: (data: any) => data.resourceUuid!,
          Service: (data: any) => data.service_uuid!,
          EmployedOutcome: () => null,
          ReferralOutcome: () => null,
          EducationOutcome: () => null,
          Outcomes: () => null,
          FormResponse: (data: any) => data.form_id!,
          NextStepDocDownload: (data: any) => data.s3Key!,
          DownloadNextStepDocsResponse: () => null,
          InviteLink: () => null,
          MultiChoiceCheckboxInputFieldOption: () => null,
          GenerateURLResponseNextStepDoc: () => null,
          PresignedPost: () => null,
          JobServiceJob: (data: any) => data.uuid!,
          SavedJob: (data: any) => data.uuid!,
          JobService_JobDto: (data: any) => data.job_id!,
          JobService_SavedJob: (data: any) => data.uuid!,
          JobService_JobSearchSkill: (data: any) => data.skill_uuid!,
          JobService_JobSearchJob: (data: any) => data.job_id!,
          JobService_JobSearchCareer: (data: any) => data.career_uuid!,
          JobService_JobSearchV2ResponseWrapper: () => null,
          JobService_GetJobsResponseWrapper: () => null,
          JobService_AdditionalSkill: (data: any) =>
            `${data.skill_uuid}-${data.custom_label}`, // we allow users add different custom label with the same classified skill (same skill uuid)
          CandidateRecommender_CandidateRecommenderOutput: () => null,
          CandidateRecommender_EmbeddedCandidates: () => null,
          CandidateRecommender_CandidateCareer: () => null,
          CandidateRecommender_CandidateEducation: () => null,
          CandidateRecommender_Candidate: (data: any) => data.id!,

          Stage: () => null,
          JobService_Stage: () => null,
          JobRecommenderEmbedded: () => null,
          JobRecommenderOutput: () => null,
          JobRecommender_Job: (data: any) => data.job_id!,
          FormVersionToFormBuilderResponse: () => null,
          InputField: () => null,
          FixedText: (data: any) => data.uuid!,
          DropdownInputField: () => null,
          DropdownInputFieldOption: () => null,
          DropdownInputFieldUserResponse: () => null,
          TextInputField: () => null,
          TextInputFieldUserResponse: () => null,
          MultiChoiceCheckboxInputField: () => null,
          MultiChoiceCheckboxInputFieldUserResponse: () => null,
          FormsListItem: () => null,
          CoachAssigned: (data: any) => data.coach_uuid!,
          CoachData: () => null,
          TenantRole: () => null,
          RoadmapResult: (data: any) => data.roadmap_uuid!,
          NextStepsLibraryResult: (data: any) => data.library_uuid!,
          Feature: (data: any) => data.feature_uuid!,
          Employer: (data: any) => data.employer_uuid || null,
          Recruiter: (data: any) => data.recruiter_uuid || null,
          Invitation: (data: any) => data.invitation_uuid || null,
          RecruiterEmployerReference: () => null,
          RecruiterResponse: (data: any) => data.recruiter_uuid || null,
          EmployerSearchResult: () => null,
          UserReference: (data: any) => data.user_uuid || null,
          RegistrationForm: (data: any) => data.form_id || null,
          RegistrationInputField: () => null,
          DropdownInputFieldWithTags: (data: any) => data.uuid || null,
          MultiChoiceCheckboxInputFieldWithTags: (data: any) =>
            data.uuid || null,
          DropdownInputFieldOptionWithTags: () => null,
          MultiChoiceCheckboxInputFieldOptionWithTags: () => null,
          SelectedMultiChoiceCheckboxInputFieldOptionWithTags: () => null,
          FormBuilderTag: (data: any) => data.uuid || null,
          JobSearchJob: (data: any) => data.job_id || null,
          CandidateRecommenderEmbedded: () => null,
          CandidateRecommenderCandidate: () => null, // Must not change to data.id, otherwise switching between career filters will display an inaccurate (cached from previous career filter) candidate match score & trainability tag for the candidate
          CandidateRecommenderCandidateCareer: () => null,
          CandidateRecommenderCandidateEducation: () => null,
          CandidateRecommenderCandidateSkill: (data: any) => data.id || null,
          CandidateRecommenderOutput: () => null,
          RecruiterSearchResult: () => null,
          CaseNoteEditor: (data: any) => data.user_uuid!,
          GetUserCaseNotesResponse: () => null,
          ExportWeeklyReport: (data: any) => data.export_report_uuid || null,
          SavedTalent: (data: any) => data.talent_uuid || null,
          CareerArea: (data: any) => data.id || null,
          Careers: () => null,
          GetCareersOutput: () => null,
          FilterOptions: () => null,
          DefaultQuestionField: () => null,
          DefaultQuestionsResponse: (data: any) => data.uuid || null,
          DefaultQuestionOptionWithTags: () => null,
          MultiChoiceCheckboxInputFieldUserResponseWithTags: () => null,
          DefaultQuestionGraphInputField: (data: any) => data.uuid || null,
          FormAndLastSubmissionResponse: () => null,
          DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags: () => null,
          DataSourceType: () => null,
        },
      }),
      authExchange<string>({
        addAuthToOperation: ({ authState, operation }) => {
          if (!authState) {
            return operation;
          }

          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState}`,
              },
            },
          });
        },
        didAuthError: ({ error }) => {
          return didRequestErrorOnAuth(error);
        },
        // Preemptively refresh the token when it's close to expiring;
        //
        // This avoid problems where an API call will communicate with multiple services
        // and our token is on the verge of expiring.  In this scenario, it's possible one
        // API call succeeds and another fails, leaving our data in an inconsistent state.
        willAuthError: ({ authState }) => {
          // if there's no auth state then there's no expiry to check so
          // return false to indicate we don't know if this will error or not
          if (!authState) {
            return false;
          }

          const decodedToken = jwtDecode<JwtPayload>(authState);
          // if we can't read the token then there's no ability to check
          // the expiration date so proceed
          if (!decodedToken || decodedToken.exp === undefined) {
            console.error(
              'Unable to decode user auth token when evaluating expiry time.'
            );
            return false;
          }

          const tokenExpiration = decodedToken.exp * 1000; // Convert to milliseconds
          const currentTime = Date.now();

          const isTokenCloseToExpiring =
            tokenExpiration - currentTime <= expirationThreshold;

          // avoid scenarios where we might end up with a partial update in the backend
          if (isTokenCloseToExpiring) {
            console.warn('Token is close to expiring so refreshing.');
            return true;
          }

          return false;
        },
        getAuth: async () => {
          let session;
          try {
            session = await getSession();
          } catch (e) {
            // do nothing
          }

          return session?.getAccessToken().getJwtToken() || '';
        },
      }),
      retryExchange({
        maxNumberAttempts: 4,
        retryIf: (error, operation) => {
          // There's no point retrying if we had an auth error.  We'll need
          // to refresh our token via didAuthError first.
          if (didRequestErrorOnAuth(error)) {
            return false;
          }

          const operationName = (operation.query.definitions[0] as any)
            .selectionSet.selections[0].name.value;

          const EMPTY_RESPONSE_ERROR =
            'Template transformation yielded an empty response.';

          if (
            operationName === 'getUserProfile' ||
            operationName === 'updateUserProfile' ||
            operationName === 'getJobRecommendations' ||
            error.message.includes(EMPTY_RESPONSE_ERROR)
          ) {
            return !!(error.graphQLErrors.length > 0 || error.networkError);
          } else {
            return !!error.networkError;
          }
        },
      }),
      sentryExchange,
      errorExchange({
        onError(error) {
          Sentry.captureException(error);
        },
      }),
      multipartFetchExchange,

      // Commenting due to removal of WebSocket server on the backend. Keeping for reference.
      // subscriptionExchange({
      //   forwardSubscription: !GRAPHQL_MESH_ENABLED
      //     ? (operation) => {
      //         const subscribe = (sink: any) => {
      //           const headers: any = (
      //             operation?.context?.fetchOptions as RequestInit
      //           )?.headers as HeadersInit;
      //           return (
      //             API.graphql(
      //               graphqlOperation(
      //                 operation.query,
      //                 operation.variables,
      //                 headers?.Authorization
      //               )
      //             ) as any
      //           ).subscribe({
      //             next: (data: any) => sink.next(data.value),
      //             error: sink.error,
      //           });
      //         };
      //         return {
      //           subscribe,
      //         };
      //       }
      //     : (operation) => {
      //         const subscribe = (sink: any) => {
      //           console.log('Mesh WS client subscribe()...');
      //           return {
      //             unsubscribe: meshWsClient.subscribe(operation, sink),
      //           };
      //         };
      //         return {
      //           subscribe,
      //         };
      //       },
      // }),
    ],
    maskTypename: true,
  });
};

const createUnauthenticatedUrqlClient = () => {
  return createClient({
    url: import.meta.env.VITE_GRAPHQL_HOST || 'localhost',
    fetchOptions: {
      headers: { 'x-api-key': import.meta.env.VITE_AWS_API_KEY! },
    },
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      sentryExchange,
      errorExchange({
        onError(error) {
          Sentry.captureException(error);
        },
      }),
      fetchExchange,
    ],
  });
};

export const GraphProvider: FC = ({ children }) => {
  const { i18n } = useTranslation();
  const { user, getSession } = useAuth();

  //i18n.language added to dependency so that whenever we change languages, we are creating a new Urql cache in order to prevent stale/old translations in previous cache from appearing. Key to persistent and correct translations across app, please do not remove.
  const urqlClient = useMemo(() => {
    if (!user.token) {
      return createUnauthenticatedUrqlClient();
    }
    return createUrqlClient({ getSession });
  }, [user, getSession, i18n.language]);

  return <Provider value={urqlClient}>{children}</Provider>;
};
